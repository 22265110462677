<template>
  <div id="EditBlogCategory">
    <b-row>
      <b-col sm="8">
        <div class="card">
          <div slot="header" class="card-header">
            <i class="fa fa-edit"></i> Blogkategorie bearbeiten
          </div>
          <div v-if="blogCategory" class="card-body">
            <b-form v-on:submit.prevent="onSubmit">
              <b-form-group validated>
                <label for="name">Name</label>
                <b-form-input type="text" id="name" required aria-required="true" v-model="blogCategory.name"
                              placeholder="Kategoriename"></b-form-input>
              </b-form-group>
              <b-form-group>
                <label for="slug">Slug</label>
                <b-form-input type="text" id="slug" v-model="blogCategory.slug" placeholder="Leer lassen um automatisch Slug zu generieren"></b-form-input>
              </b-form-group>
              <div class="footer-btn" slot="footer">
                <b-button type="submit" size="sm" variant="primary"><i class="fa fa-save"></i> Speichern</b-button>
                <b-button size="sm" href="/#/blog/blogCategories/" variant="danger"><i class="fa fa-ban"></i>Abbrechen
                </b-button>
              </div>
            </b-form>
          </div>
          <br>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

  import {mapGetters} from 'vuex'

  const axios = require('axios')

  export default {
    name: 'editBlogCategory',
    computed: {
      ...mapGetters({
        blogCategory: 'blogCategory/getBlogCategory',
        error: 'blogCategory/getError'
      })
    },
    mounted() {
      if (this.$route.params.id > 0) {
        this.$store.dispatch('blogCategory/loadBlogCategory', this.$route.params.id)
      } else {
        this.$store.dispatch('blogCategory/initBlogCategory')
      }
    },
    methods: {
      onSubmit() {
        this.$store.dispatch('blogCategory/saveBlogCategory', {
          id: this.$route.params.id,
          name: this.blogCategory.name,
          slug: this.blogCategory.slug,
        }).then(response => {
          if (response.status == 200 || response.status == 201) {
            this.$router.replace(this.$route.query.redirect || '/blog/blogCategories')
          }
        }, error => {

        })
      }
    }
  }
</script>
